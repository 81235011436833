import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    console.log("controller")
    console.log(this.element)
    console.log(this.element.querySelectorAll('a'))
    this.element.querySelectorAll('a').forEach(function(link) {
      console.log(link)
      if (link.host !== window.location.host) {
        console.log(link)
        link.target = "_blank"
      }
    })
  }
}
